<script lang="ts" setup>
import { computed } from 'vue'
import LocationOutlined from '../icons/figma/LocationOutlined.vue'
import BusinessCenterOutlined from '../icons/figma/BusinessCenterOutlined.vue'
import PushPinOutlined from '../icons/figma/PushPinOutlined.vue'
import AttachMoney from '../icons/figma/AttachMoney.vue'
import CorporateFareOutlined from '../icons/figma/CorporateFareOutlined.vue'
import MobilePhoneOutlined from '../icons/figma/MobilePhoneOutlined.vue'
import EmailOutlined from '../icons/figma/EmailOutlined.vue'
import PeopleOutlined from '../icons/figma/PeopleOutlined.vue'
import WebsiteOutlined from '../icons/figma/WebsiteOutlined.vue'
import FbFilled from '../icons/figma/FbFilled.vue'
import LinkedInFilled from '../icons/figma/LinkedInFilled.vue'
import ClockOutlined from '../icons/figma/ClockOutlined.vue'
import { getReadableDayDifferenceFromToday } from '../../../utils/common'
import PeopleFilled from '../icons/figma/PeopleFilled.vue'
const toLocalePath = useLocalePath()
const { t } = useI18n()

type IconInfo = {
  icon: any
  label: string
  link: string
}

type TVariant = 'small' | 'normal' | 'col'

export type TCompany = {
  idSlug: string
  to: string
  createdAt: string
  name: string
  description?: string | undefined
  location?: string | undefined
  tel?: string | undefined
  email?: string | undefined
  industry?: string | undefined
  type?: 'SELF_ONBOARDED' | 'BD_CREATED' | 'SCRAPED'
  website?: string | undefined
  facebook?: string | undefined
  linkedin?: string | undefined
  instagram?: string | undefined
  logo?: {
    url?: string
    width?: number | null
    height?: number | null
  }
  banner?: {
    url?: string
    width?: number | null
    height?: number | null
  }
  youtubeCoverVideo?: string
  youtubeVideo?: Array<string>
  scale?: string
  leadSource?: string
  contactPerson?: string
  contactEmail?: string
  contactPhone?: string
  archived?: boolean
  activeJobs?: number
  needVerification?: boolean
}

export type TJob = {
  idSlug: number | string
  to: string
  companyIdSlug?: number | string
  companyName?: string
  company?: TCompany
  title: string
  industry?: string | undefined
  location?: string | undefined
  employmentType?: string | undefined
  workMode?: string | undefined
  role?: string | undefined
  logo?:
    | {
        url?: string
        width?: number | null
        height?: number | null
      }
    | undefined
  experienceYear?: string | undefined
  salary?: string | undefined
  status?: string | undefined | null
  description?: string | undefined
  saved?: boolean | undefined
  savedIdSlug?: string | undefined
  postingDate: string
  closingDate: string
  boosted?: boolean
  featured?: boolean
  geoCodeWhiteList?: string[]
  exclusiveToUserTags?: string[]
}

const props = defineProps({
  job: {
    type: Object as PropType<TJob>,
    default: () => null,
    required: false
  },
  company: {
    type: Object as PropType<TCompany>,
    default: () => null,
    required: false
  },
  variant: {
    type: String as PropType<TVariant>,
    default: 'normal',
    required: false
  },
  redirect: {
    type: Boolean,
    default: true,
    required: false
  },
  borderRadiusOverride: {
    type: String,
    default: '',
    required: false
  },
  borderLeft: {
    type: String,
    default: '',
    required: false
  },
  borderRight: {
    type: String,
    default: '',
    required: false
  },
  borderTop: {
    type: String,
    default: '',
    required: false
  },
  borderBottom: {
    type: String,
    default: '',
    required: false
  },
  borderColorOverride: {
    type: String,
    default: '',
    required: false
  },
  bgColorOverride: {
    type: String,
    default: '',
    required: false
  },
  noPadding: {
    type: Boolean,
    default: false,
    required: false
  },
  showIcons: {
    type: Boolean,
    default: false
  },
  showJobRole: {
    type: Boolean,
    default: true
  },
  showJobLocation: {
    type: Boolean,
    default: true
  },
  showJobPostingDate: {
    type: Boolean,
    default: true
  },
  showJobEmploymentType: {
    type: Boolean,
    default: true
  },
  showJobWorkMode: {
    type: Boolean,
    default: true
  },
  showJobIndustry: {
    type: Boolean,
    default: true
  },
  showJobExperienceYear: {
    type: Boolean,
    default: true
  },
  showJobSalary: {
    type: Boolean,
    default: true
  }
})

const slots = useSlots()

const jobInfo = computed<IconInfo[] | undefined>(() => {
  const info: IconInfo[] = []

  if (props.job?.role && props.showJobRole) {
    info.push({
      icon: PeopleFilled,
      label: props.job.role,
      link: ''
    })
  }
  if (props.job?.location && props.showJobLocation) {
    info.push({
      icon: LocationOutlined,
      label: props.job.location,
      link: ''
    })
  }
  if (props.job?.postingDate && props.showJobPostingDate) {
    const dayDiff = getReadableDayDifferenceFromToday(t, props.job.postingDate, 7)
    info.push({
      icon: ClockOutlined,
      label: dayDiff,
      link: ''
    })
  }
  if (props.job?.employmentType && props.showJobEmploymentType) {
    info.push({ icon: BusinessCenterOutlined, label: props.job.employmentType, link: '' })
  }
  if (props.job?.workMode && props.showJobWorkMode) {
    info.push({ icon: PushPinOutlined, label: props.job.workMode, link: '' })
  }
  if (props.job?.industry && props.showJobIndustry) {
    info.push({ icon: CorporateFareOutlined, label: props.job.industry, link: '' })
  }
  if (props.job?.experienceYear && props.showJobExperienceYear) {
    info.push({
      icon: BusinessCenterOutlined,
      label: `${props.job.experienceYear} years`,
      link: ''
    })
  }
  if (props.job?.salary && props.showJobSalary) {
    info.push({ icon: AttachMoney, label: `${props.job.salary}`, link: '' })
  }

  return info
})

const companyInfo = computed<IconInfo[] | undefined>(() => {
  const info: IconInfo[] = []
  if (props.company?.location) {
    info.push({
      icon: LocationOutlined,
      label: props.company.location.split('|').join(', '),
      link: ''
    })
  }
  if (props.company?.tel) {
    info.push({ icon: MobilePhoneOutlined, label: props.company.tel, link: '' })
  }
  if (props.company?.email) {
    info.push({ icon: EmailOutlined, label: props.company.email, link: '' })
  }

  return info
})

const companyInfo2 = computed<IconInfo[] | undefined>(() => {
  const info: IconInfo[] = []
  if (props.company?.industry) {
    info.push({ icon: CorporateFareOutlined, label: props.company.industry, link: '' })
  }

  if (props.company?.scale) {
    info.push({ icon: PeopleOutlined, label: props.company.scale, link: '' })
  }
  if (props.company?.website) {
    info.push({ icon: WebsiteOutlined, label: '', link: props.company.website })
  }
  if (props.company?.facebook) {
    info.push({ icon: FbFilled, label: '', link: props.company.facebook })
  }
  if (props.company?.linkedin) {
    info.push({ icon: LinkedInFilled, label: '', link: props.company.linkedin })
  }

  return info
})

function toTitleSlug(title: string) {
  // replace spaces and / with -
  return encodeURIComponent(title.replace(/[\s/]/g, '-'))
}
</script>

<template>
  <div class="w-full" :class="props.variant === 'col' ? 'h-auto' : 'h-fit'">
    <div
      v-if="variant === 'normal'"
      class="flex flex-col justify-between gap-[1.25rem] rounded-[0.63rem] border-[1px] border-gray-50 p-[1.25rem] lg:flex-row lg:p-[1.88rem]"
      :style="{
        borderRadius: props.borderRadiusOverride ? props.borderRadiusOverride : undefined,
        borderLeft: props.borderLeft ? props.borderLeft : undefined,
        borderRight: props.borderRight ? props.borderRight : undefined,
        borderTop: props.borderTop ? props.borderTop : undefined,
        borderBottom: props.borderBottom ? props.borderBottom : undefined,
        borderColor: props.borderColorOverride ? props.borderColorOverride : undefined,
        backgroundColor: props.bgColorOverride ? props.bgColorOverride : 'white',
        padding: props.noPadding ? '0 !important' : undefined
      }"
    >
      <div class="flex flex-col gap-[0.31rem] lg:flex-row lg:gap-[1.25rem]">
        <div class="lg:flex-0 w-fit lg:flex lg:w-[7.5rem] lg:justify-center">
          <slot v-if="props.job ? props.job.logo : props.company.logo" name="company-logo"></slot>
          <Placeholder v-else class="hidden lg:flex" />
        </div>
        <div class="flex-1">
          <div class="mb-[0.31rem] flex flex-col gap-[0.31rem] lg:mb-[0.62rem]">
            <div
              v-if="props.job && props.job.featured"
              class="flex flex-row gap-[0.31rem] py-[0.31rem]"
            >
              <nuxt-picture
                format="webp"
                src="/images/logo-square.png"
                width="16"
                height="16"
                :preload="false"
                :lazy="true"
                alt="Featured Job"
              />
              <div class="text-t6 font-light text-[#B483D4]">
                {{ $t('jobBoard.adminPage.featured') }}
              </div>
            </div>
            <a
              v-if="props.redirect"
              :title="props.job ? props.job.title : props.company.name"
              :href="toLocalePath(`/jobs/${props.job.idSlug}---${toTitleSlug(props.job.title)}`)"
              class="cursor-pointer text-h5 font-medium leading-6 hover:underline lg:text-h4"
              target="_blank"
            >
              {{ props.job ? props.job.title : props.company.name }}
            </a>
            <div v-else class="text-h5 font-medium leading-6 lg:text-h4">
              {{ props.job ? props.job.title : props.company.name }}
            </div>
            <div
              v-if="props.job && props.job.companyName"
              class="text-t6 font-light text-gray-50 lg:text-t5"
            >
              {{ props.job.companyName }}
            </div>
          </div>
          <div class="flex flex-wrap gap-x-[0.62rem] text-t6 font-light text-primary">
            <div
              v-for="item in props.job ? jobInfo : companyInfo"
              :key="item.icon"
              class="flex flex-row gap-[0.31rem] py-[0.32rem]"
            >
              <div>
                <a
                  v-if="item.link"
                  :href="item.link"
                  class="cursor-pointer text-h5 font-medium leading-6 hover:underline lg:text-h4"
                  target="_blank"
                >
                  <component
                    :is="item.icon"
                    v-if="item.label || item.link"
                    class="h-[1rem] w-[1rem]"
                  />
                </a>
                <component
                  :is="item.icon"
                  v-if="!item.link && item.label"
                  class="h-[1rem] w-[1rem]"
                />
              </div>
              <div v-if="item.label">{{ item.label }}</div>
            </div>
          </div>
          <div
            v-if="companyInfo2"
            class="flex flex-wrap gap-x-[0.62rem] text-t6 font-light text-primary"
          >
            <div
              v-for="item in companyInfo2"
              :key="item.icon"
              class="flex flex-row gap-[0.31rem] py-[0.62rem]"
            >
              <div>
                <a
                  v-if="item.link"
                  :href="item.link"
                  class="cursor-pointer text-h4 font-medium leading-6 hover:underline"
                  target="_blank"
                >
                  <component
                    :is="item.icon"
                    v-if="item.label || item.link"
                    class="h-[1rem] w-[1rem]"
                  />
                </a>
                <component
                  :is="item.icon"
                  v-if="!item.link && item.label"
                  class="h-[1rem] w-[1rem]"
                />
              </div>
              <div v-if="item.label">{{ item.label }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="slots['save-button'] || slots['apply-button']"
        class="float-right flex min-w-fit gap-[0.62rem] md:justify-end"
      >
        <slot name="save-button"> </slot>
        <slot name="apply-button"></slot>
      </div>
    </div>
    <div
      v-if="variant === 'small'"
      class="flex w-full flex-row gap-[1.25rem] rounded-card border-[1px] border-gray-50 bg-white p-[1.25rem] lg:w-[21.875rem]"
    >
      <div
        class="flex-0 flex min-w-[7.5rem] max-w-[7.5rem] justify-center rounded-[0.3125rem] text-center"
      >
        <slot v-if="props.job.logo" name="company-logo"></slot>
        <Placeholder v-else class="flex w-[6.75rem]" />
      </div>
      <div class="flex flex-col">
        <a
          v-if="props.redirect"
          :title="props.job.title"
          :href="toLocalePath(`/jobs/${props.job.idSlug}---${toTitleSlug(props.job.title)}`)"
          class="cursor-pointer text-h6 font-medium hover:underline"
          target="_blank"
        >
          {{ props.job.title }}
        </a>
        <div class="text-t6 font-light text-gray-50">
          {{ props.job.companyName }}
        </div>
        <div class="text-t6 font-light text-gray-50">
          {{ props.job.location }}
        </div>
      </div>
    </div>
    <div
      v-if="variant === 'col'"
      class="flex h-full w-full flex-col gap-[0.31rem] rounded-card border-[1px] border-gray-50 bg-white p-[1.25rem]"
    >
      <div class="h-fit w-fit items-center lg:flex lg:h-[7.5rem] lg:justify-center">
        <slot v-if="props.job ? props.job.logo : props.company.logo" name="company-logo"></slot>
        <Placeholder v-else class="hidden lg:flex" />
      </div>
      <a
        v-if="props.redirect"
        :title="props.job.title"
        :href="toLocalePath(`/jobs/${props.job.idSlug}---${toTitleSlug(props.job.title)}`)"
        class="line-clamp-2 cursor-pointer text-h6 font-medium hover:underline"
        target="_blank"
      >
        {{ props.job.title }}
      </a>
      <div class="line-clamp-2 text-t6 font-light text-gray-50">
        {{ props.job.companyName }}
      </div>
      <div v-if="props.showIcons">
        <div class="flex flex-wrap gap-x-[0.62rem] text-t6 font-light text-primary">
          <div
            v-for="item in props.job ? jobInfo : []"
            :key="item.icon"
            class="flex flex-row gap-[0.31rem] py-[0.32rem]"
          >
            <div>
              <a
                v-if="item.link"
                :href="item.link"
                class="cursor-pointer text-h5 font-medium leading-6 hover:underline lg:text-h4"
                target="_blank"
              >
                <component
                  :is="item.icon"
                  v-if="item.label || item.link"
                  class="h-[1rem] w-[1rem]"
                />
              </a>
              <component
                :is="item.icon"
                v-if="!item.link && item.label"
                class="h-[1rem] w-[1rem]"
              />
            </div>
            <div v-if="item.label">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="mb-0 mt-auto flex flex-col">
        <slot name="save-button"></slot>
      </div>
    </div>
  </div>
</template>
